import { Link } from 'react-router-dom';

export default function MiddleBarMobile() {
  return (
    <>
      <div className="mt-4 py-4 px-5 ">
        <h3>SPEED FORGE SCHOOL</h3>
        <p>
          Explore a dedicated section on our website filled with valuable resources to improve your driving skills, boost safety awareness, and better understand traffic
          rules and road user behavior. This section is designed to help drivers of all levels become more confident and responsible on the road.
        </p>
        <Link className="btn btn-outline-dark btn-sm" to="/school">
          HAVE A LOOK
        </Link>
      </div>
    </>
  );
}
