import { useEffect, useState } from 'react';
import Middlebar from './middle-bar/Middle.bar';
import MiddleBarMobile from './middle-bar/Middle.bar.mobile';
import Topbar from './top-bar/Top.bar';
import TopBarMobile from './top-bar/Top.bar.mobile';

export default function Header() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Отслеживание изменения размера экрана
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Очистка события при размонтировании компонента
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header>
      {isMobile ? (
        <div className="">
          <div className="">
            <TopBarMobile />
            <MiddleBarMobile />
          </div>
        </div>
      ) : (
        <div className="top-container-background">
          <div className="container-fluid container-fluid-top">
            <Topbar />
            <Middlebar />
          </div>
        </div>
      )}

      <div className="middle-line"> &nbsp;</div>
    </header>
  );
}
