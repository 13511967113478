import { useEffect, useState } from 'react';
import { PaginatedListDTO } from '../../shared/interfaces/common/PaginatedList.DTO';
import api from '../../shared/services/api';
import { TrackDTO } from '../../shared/interfaces/tracks/Track.DTO';
import { Link } from 'react-router-dom';

export default function GetTrackList() {
  const [tracks, setTracks] = useState<PaginatedListDTO<TrackDTO> | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/TracksRequest/GetTrackList?pageIndex=1&pageSize=10');
        setTracks(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  if (!tracks) {
    return <>Loading</>;
  }

  return (
    <>
      {tracks.items.map((track) => (
        <div key={track.id}>
          <Link to={`/tracks/view/${track.url}`}>{track.name}</Link>
        </div>
      ))}
    </>
  );
}
