import { NavLink } from 'react-router-dom';

export default function TopBarMobile() {
  return (
    <div className="d-flex align-items-center p-3 text-white bg-dark">
      {/* Логотип слева */}
      <div className="flex-shrink-0">
        <h1 className="m-0">
          SPEED <br /> FORGE
        </h1>
      </div>

      {/* Кнопка для гамбургера и навигационное меню справа */}
      <div className="ms-auto">
        <nav className="navbar navbar-expand-lg navbar-dark">
          {/* Кнопка для гамбургера на мобильных устройствах */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Навигационное меню */}
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav py-5">
              <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" to="/clubs">
                  Clubs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" to="/tracks">
                  Tracks
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" to="/school">
                  School
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}
