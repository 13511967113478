import { Route, Routes } from 'react-router-dom';
import ClubsHomePage from '../pages/ClubsHome.Page';
import ClubPage from '../pages/Club.Page';

export default function ClubsNonAuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ClubsHomePage />}></Route>
      <Route path="/view/:url" element={<ClubPage />}></Route>
    </Routes>
  );
}
