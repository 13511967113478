import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './shared/components/layout/Layout';
import SchoolNonAuthRoutes from './school/routes/School.NonAuth.Routes';
import SchoolHomePage from './school/pages/SchoolHome.Page';
import ClubsNonAuthRoutes from './clubs/routes/Clubs.NonAuth.Routes';
import TracksNonAuthRoutes from './tracks/routes/Tracks.NonAuth.Routes';
import HomePage from './home/pages/Home.page';

function App() {
  return (
    <>
      {/* prettier-ignore */}
      <BrowserRouter>
        <Routes>
          <Route element={<><Layout /></>}>
            <Route path="/" element={<HomePage />} />
            <Route path="account/*" element={<>Some path</>} />
            <Route path="identity/*" element={<>Some path</>} />
            <Route path="school/*" element={<SchoolNonAuthRoutes />} />
            <Route path="clubs/*" element={<ClubsNonAuthRoutes />} />
            <Route path="tracks/*" element={<TracksNonAuthRoutes/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
