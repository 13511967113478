import { useEffect, useState } from 'react';
import { PaginatedListDTO } from '../../shared/interfaces/common/PaginatedList.DTO';
import { ClubDTO } from '../../shared/interfaces/clubs/Club.DTO';
import api from '../../shared/services/api';
import { Link } from 'react-router-dom';

export default function GetClubList() {
  const [clubs, setClubs] = useState<PaginatedListDTO<ClubDTO> | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/ClubsRequest/GetClubList?pageIndex=1&pageSize=10');
        setClubs(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  if (!clubs) {
    return <>Loading</>;
  }

  return (
    <>
      {clubs.items.map((club) => (
        <div key={club.id}>
          <Link to={`/clubs/view/${club.url}`}>{club.name}</Link>
        </div>
      ))}
    </>
  );
}
