import { useEffect, useState } from 'react';
import { ClubDTO } from '../../shared/interfaces/clubs/Club.DTO';
import { useParams } from 'react-router-dom';
import api from '../../shared/services/api';
import DOMPurify from 'dompurify';
import ContentLayoutContainer from '../../shared/components-ui/containers/ContentLayout.Container';
import { Helmet } from 'react-helmet-async';

export default function ClubPage() {
  const [club, setClub] = useState<ClubDTO | null>(null);
  const { url } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(`/ClubsRequest/GetClubByUrl?url=${url}`);
      setClub(response.data);
    };
    fetchData();
  }, []);

  if (!club) {
    return <>Loading</>;
  }

  const stripHtmlTags = (html: string) => {
    return html.replace(/<\/?[^>]+(>|$)/g, '');
  };

  // Генерация мета-описания: ограничение до 150 символов, обрезка слов и добавление многоточия, если текст был обрезан.
  const truncateDescription = (description: string, maxLength: number) => {
    const strippedDescription = stripHtmlTags(description);
    if (strippedDescription.length <= maxLength) {
      return strippedDescription;
    }

    // Обрезаем строку до максимально допустимой длины
    const truncated = strippedDescription.substring(0, maxLength);

    // Находим последнее пространство, чтобы избежать обрыва слова
    const lastSpaceIndex = truncated.lastIndexOf(' ');

    // Если пробел найден, обрезаем строку до этого места
    const finalDescription = lastSpaceIndex > 0 ? truncated.substring(0, lastSpaceIndex) : truncated;

    return finalDescription + '...';
  };

  const metaDescription = truncateDescription(club.description, 150);

  return (
    <>
      <ContentLayoutContainer>
        <Helmet>
          <title>{club.name}</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <h3>{club.name}</h3>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(club.description) }}></div>
        <a href={club.link} target="_blank">
          Link to club website
        </a>
      </ContentLayoutContainer>
    </>
  );
}
