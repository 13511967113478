import { Route, Routes } from 'react-router-dom';
import TracksHomePage from '../pages/TracksHome.Page';
import TrackPage from '../pages/Track.Page';

export default function TracksNonAuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<TracksHomePage />}></Route>
      <Route path="/view/:url" element={<TrackPage />}></Route>
    </Routes>
  );
}
