import { Helmet } from 'react-helmet-async';
import ContentLayoutContainer from '../../shared/components-ui/containers/ContentLayout.Container';
import GetTrackList from '../components/GetTrackList';

export default function TracksHomePage() {
  const title = 'Track list';
  const description = 'This is a list of tracks.';

  return (
    <>
      <ContentLayoutContainer>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <h1>{title}</h1>
        <p>{description}</p>
        <div className="row">
          <div className="col-12 col-md-9">
            <GetTrackList />
          </div>
          <div className="col-12 col-md-3"></div>
        </div>
      </ContentLayoutContainer>
    </>
  );
}
