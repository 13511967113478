import { useEffect, useState } from 'react';
import { TrackDTO } from '../../shared/interfaces/tracks/Track.DTO';
import { useParams } from 'react-router-dom';
import api from '../../shared/services/api';
import ContentLayoutContainer from '../../shared/components-ui/containers/ContentLayout.Container';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet-async';

export default function TrackPage() {
  const [track, setTrack] = useState<TrackDTO | null>(null);
  const { url } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(`/TracksRequest/GetTrackByUrl?url=${url}`);
      setTrack(response.data);
    };
    fetchData();
  }, []);

  if (!track) {
    return <>Loading</>;
  }

  const stripHtmlTags = (html: string) => {
    return html.replace(/<\/?[^>]+(>|$)/g, '');
  };

  // Генерация мета-описания: ограничение до 150 символов, обрезка слов и добавление многоточия, если текст был обрезан.
  const truncateDescription = (description: string, maxLength: number) => {
    const strippedDescription = stripHtmlTags(description);
    if (strippedDescription.length <= maxLength) {
      return strippedDescription;
    }

    // Обрезаем строку до максимально допустимой длины
    const truncated = strippedDescription.substring(0, maxLength);

    // Находим последнее пространство, чтобы избежать обрыва слова
    const lastSpaceIndex = truncated.lastIndexOf(' ');

    // Если пробел найден, обрезаем строку до этого места
    const finalDescription = lastSpaceIndex > 0 ? truncated.substring(0, lastSpaceIndex) : truncated;

    return finalDescription + '...';
  };

  const metaDescription = truncateDescription(track.description, 150);

  return (
    <>
      <ContentLayoutContainer>
        <Helmet>
          <title>{track.name}</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <h3>{track.name}</h3>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(track.description) }}></div>
        <a href={track.link} target="_blank">
          Link to track website
        </a>
      </ContentLayoutContainer>
    </>
  );
}
