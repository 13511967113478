import { Link } from 'react-router-dom';
import ContentLayoutContainer from '../../shared/components-ui/containers/ContentLayout.Container';
import { Helmet } from 'react-helmet-async';

export default function HomePage() {
  const title = 'Welcome to Speed forge';
  const description =
    'Speed Forge: Enhance your driving skills with our dedicated school, discover thrilling race tracks, and join passionate car clubs. Learn, practice, and connect with fellow car enthusiasts.';

  return (
    <ContentLayoutContainer>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <h1>Welcome to Speed forge</h1>
      <hr />
      <div>
        <h4>SPEED FORGE SCHOOL</h4>
        <p>Explore a dedicated section filled with resources to improve your driving skills, increase safety awareness, and understand the rules of the road.</p>
        <Link to={'school'}>Explore School</Link>
      </div>
      <hr />
      <div>
        <h4>Race Tracks</h4>
        <p>
          Discover exciting race tracks and learn more about the different car clubs that participate in various events. Find the perfect place to practice your skills or
          join in the excitement.
        </p>
        <Link to={'tracks'}>Explore Tracks</Link>
      </div>
      <hr />
      <div>
        <h4>Auto Clubs</h4>
        <p>Connect with car enthusiasts through the various clubs available at Speed Forge. Learn more about their events, goals, and how you can get involved.</p>
        <Link to={'clubs'}>Explore Clubs</Link>
      </div>
    </ContentLayoutContainer>
  );
}
