import { useEffect, useState } from 'react';
import { LessonDTO } from '../../shared/interfaces/school/Lesson.DTO';
import api from '../../shared/services/api';
import { PaginatedListDTO } from '../../shared/interfaces/common/PaginatedList.DTO';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import YouTubeVideoPlayer from './YuoTube.VideoPlayer';

export default function LessonList() {
  const [lessons, setLessons] = useState<PaginatedListDTO<LessonDTO> | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/SchoolRequest/GetLessonsFullList');
        setLessons(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  if (!lessons) {
    return <> no data</>;
  }

  return (
    <>
      {lessons.items.map((lesson) => (
        <div key={lesson.id}>
          <Link to={`/school/view/${lesson.url}`}>{lesson.name}</Link>
          {lesson.isVideoPresentation ? (
            <div className="video-container">
              <div className="video-container">
                <YouTubeVideoPlayer videoLink={lesson.videoPresentationLink} width={'100%'} height={'420'} />
              </div>
            </div>
          ) : (
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lesson.presentation) }}></p>
          )}
        </div>
      ))}
    </>
  );
}
