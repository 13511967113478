import { useEffect, useState } from 'react';
import ContentLayoutContainer from '../../shared/components-ui/containers/ContentLayout.Container';
import { CategoryDTO } from '../../shared/interfaces/school/Category.DTO';
import api from '../../shared/services/api';
import CategoryList from '../components/CategoryList';
import LessonList from '../components/Lesson.List';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function SchoolHomePage() {
  const metaDescription =
    'Explore a dedicated section on our website filled with valuable resources to improve your driving skills, boost safety awareness, and better understand traffic rules and road user behavior. This section is designed to help drivers of all levels become more confident and responsible on the road.';

  return (
    <ContentLayoutContainer>
      <Helmet>
        <title>Speed forge school</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div className="row">
        <div className="col-12 col-md-9">
          <LessonList />
        </div>
        <div className="col-12 col-md-3">
          <CategoryList />
        </div>
      </div>
    </ContentLayoutContainer>
  );
}
